import React from "react"
import Header from "./header"
import Footer from "./footer"

export default function Layout({activePage,children }) {
  return (
    <div className="min-h-screen relative">
      <Header activePage={activePage}/>
      <div className="pt-28 container pb-16">{children}</div>
      <Footer />
    </div>
  )
}
