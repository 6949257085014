import React from "react"
import FaithTech from "../../static/images/faithtech_logo.svg"

export default () => (
  <div className="h-16 bg-gray-800 absolute w-full bottom-0 flex items-center">
    <div className="container">
      <a
        href="https://faithtech.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="flex text-white">
          <img src={FaithTech} alt="FaithTech logo" className="w-24 mr-4" />A
          FaithTech Labs Project
        </div>
      </a>
    </div>
  </div>
)
